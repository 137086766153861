/* globals process */
import React from 'react';
import { Helmet } from 'react-helmet';
import PoseBody from '../components/pose-body';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { linkResolver } from '../utils/linkResolver';
import { graphql } from 'gatsby';

function PoseTemplate ({ data }) {
  const doc = data.prismicPose.data;

  return (
    <>
      <Helmet>
        <title>{`Yoga Therapy Library | ${doc.pose.name}`}</title>
        <meta name="og:title" content={`Yoga Therapy Library | ${doc.pose.name}`} />
        <meta name="description" content={`Yoga Therapy Library | ${doc.pose.name}`} />
        <meta name="og:description" content={`Yoga Therapy Library | ${doc.pose.name}`} />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <PoseBody
        data={doc}
      />
    </>
  );
}

export default withPrismicPreview(PoseTemplate, [
  {
    repositoryName: process.env.PRISMIC_REPOSITORY,
    linkResolver
  }
]);

export const query = graphql`
query PoseTypeQuery($id: String) {
  prismicPose(id: { eq: $id }) {
    _previewable
    data {
      pose {
        name
        nameSanskrit
        category
        props
        tiytRatingNotes
        tiytScale
        mitigationStrategies
        increasingPotentialReactions
        instructionalText
        affirmation
        mudra
        pranayama
        nameSymbolism
        drishti
        illustrations {
          adam { file { url } }
          antonio { file { url } }
          bob { file { url } }
          curt { file { url } }
          diego { file { url } }
          dinah { file { url } }
          elena { file { url } }
          emi { file { url } }
          fatima { file { url } }
          heidi { file { url } }
          isha { file { url } }
          jaden { file { url } }
          jax { file { url } }
          kyung { file { url } }
          levi { file { url } }
          lin { file { url } }
          naomi { file { url } }
          natasha { file { url } }
          nora { file { url } }
          piolo { file { url } }
          ravi { file { url } }
          rosa { file { url } }
          silvia { file { url } }
          suli { file { url } }
          tamika { file { url } }
          willow { file { url } }
          wolf { file { url } }
          yasmine { file { url } }
        }
        variations {
          id
          illustrations {
            adam { file { url } }
            antonio { file { url } }
            bob { file { url } }
            curt { file { url } }
            diego { file { url } }
            dinah { file { url } }
            elena { file { url } }
            emi { file { url } }
            fatima { file { url } }
            heidi { file { url } }
            isha { file { url } }
            jaden { file { url } }
            jax { file { url } }
            kyung { file { url } }
            levi { file { url } }
            lin { file { url } }
            naomi { file { url } }
            natasha { file { url } }
            nora { file { url } }
            piolo { file { url } }
            ravi { file { url } }
            rosa { file { url } }
            silvia { file { url } }
            suli { file { url } }
            tamika { file { url } }
            willow { file { url } }
            wolf { file { url } }
            yasmine { file { url } }
          }
          name
        }
      }

      body {
        __typename
        ... on PrismicPoseDataBodyArticleContent {
          ... PoseArticleContentFields
        }

        ... on PrismicPoseDataBodyArticleDownloads {
          ... PoseArticleDownloadsFields
        }

        ... on PrismicPoseDataBodyArticleVideo {
          ... PoseArticleMediaFields
        }
        ... on PrismicPoseDataBodyCustomCode {
          ... PoseBodyCustomCodeFields
        }
      }

      body1 {
        __typename
        ... on PrismicPoseDataBody1ComingSoonArticleFeature {
          ... PoseComingSoonArticleFields
        }
        ... on PrismicPoseDataBody1FeaturedTestimonial {
          ... PoseFeaturedTestimonialFields
        }
        ... on PrismicPoseDataBody1SubscriptionPricing {
          ... PoseSubscriptionPricingFields
        }
        ... on PrismicPoseDataBody1ThreeColCta {
          ... PoseThreeColCtaFields
        }
        ... on PrismicPoseDataBody1TwoColumnSection {
          ... PoseTwoColumnSectionFields
        }
        ... on PrismicPoseDataBody1CustomCode {
          ... PoseBody1CustomCodeFields
        }
      }
    }
  }
}
`;
