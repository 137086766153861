import React from 'react';
import SliceZone from '../slice-zone';
import InteractiveFeature from '../slice-zone/slices/interactive-pose-feature/interactive-feature';
import './styles.scss';

export default function PoseBody ({ data }) {
  const {
    pose,
    body,
    body1
  } = data;

  const {
    name: poseName,
    nameSanskrit,
    illustrations,
    variations
  } = pose;

  return (
    <div className="pose-body">
      <div className="pose-body__inner container">
        <div className="pose-content">
          <InteractiveFeature items={variations} featuredPose={pose} pageType="pose" />
        </div>
        <div className="slices-container">
          <SliceZone sliceZone={body} />
        </div>
        <div className="extra-slices-container">
          <SliceZone sliceZone={body1} />
        </div>
      </div>
    </div>
  );
}
